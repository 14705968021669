
import { defineComponent, reactive, ref, onMounted } from 'vue'
import Facility from '@/models/facility'
import Brand from '@/models/brand'
import User from '@/models/user'
import http from "@/modules/httpclient"
import { ElNotification } from 'element-plus'

export default defineComponent({
    props:{
        loginUser:User
    },
    setup() {
        const searchCondition = reactive<Facility>(new Facility('', '', '', '', '', '', '', '', '', '', null, null, null, '', 0, ''))
        const facilities = reactive<Facility[]>([])
        const facility = reactive<Facility>(new Facility('', '', '', '', '', '', '', '', '', '', null, null, null, '', 0, ''))
        const brands = reactive<Brand[]>([])

        const isEdit = ref(false)
        const isDeleteVisible = ref(false)
        const isDialogVisible = ref(false)
        const isConfirmVisible = ref(false)
        const isSearchVisible = ref(false)
        const form = ref()
        const filter = ref('')

        const dispColumns = reactive({
            facilityID: false,
            facilityName: true,
            facilityCode: true,
            brandID: false,
            brandName: true,
            zip:false,
            state:true,
            city:true,
            address:false,
            phone:false,
            longitude: false,
            latitude: false,
            picture: false,
            range: false
        })

        const clearValidate = () => {
            form.value.clearValidate()
        }

        // 入力チェック
        const rules = {
            facilityName: [
                {
                    required: true,
                    message: '必須入力です',
                    trigger: 'blur'
                }
            ],
            facilityCode: [
                // 仕様変更で一意にしない。m_facilityだけの対応
                // {
                //     validator: async (rule, value, callback) => {
                        
                //         if(isEdit.value){
                //             callback()
                //         }
                //         // brand_id と合わせてユニークにする
                //         if(!facility.brandID){
                //             // brand_id が未選択の場合は、一旦通す。必須なので登録前には必ずチェックすることになる
                //             callback()
                //         }

                //         try{
                //             const res = await http.get('/v1/facilities',{
                //                 params:{
                //                     facilityCode:value,
                //                     brandID:facility.brandID,
                //                     isDeleted:1 // 削除済みも含む
                //                 }
                //             })
                //             if(res.data.facilities.length > 0){
                //                 callback(new Error('すでに使用されているコードです'))
                //             }
                //             else{
                //                 // OK
                //                 callback()
                //             }
                //         }
                //         catch (reason){
                //             ElNotification({ type: 'error', message: '予期しないエラーが発生しました' })
                //             callback(new Error('予期しないエラーが発生しました'))
                //         }
                //     },
                //     trigger: 'blur'
                // },
                {
                    required: true,
                    message: '必須入力です',
                    trigger: 'blur'
                }
            ],
            brandID: [
                {
                    required: true,
                    message: '選択必須です',
                    trigger: 'blur'
                }
            ],
            // zip: [
            //     {
            //         // NOTE: countryがあるので海外も想定しているようだが、一旦は日本向けでチェックする
            //         pattern:/^[0-9]{7}$/,
            //         message: '郵便番号（ハイフンなし）を入力してください',
            //         trigger: 'blur'
            //     }
            // ],
            // phone: [
            //     {
            //         // NOTE: countryがあるので海外も想定しているようだが、一旦は日本向けでチェックする
            //         // NOTE: ひとまず緩くチェックする
            //         pattern:/^0[0-9]{9,10}$/,
            //         message: '電話番号（ハイフンなし）を入力してください',
            //         trigger: 'blur'
            //     }
            // ],
            longitude: [
                {
                    type:'number',
                    min:-180,
                    max:180,
                    message: '-180~180の間で入力してください',
                    trigger: 'blur',
                    transform(value){
                        // 数値を入れた後に消すと空白になって型チェックでエラーになるのでnullに変換する
                        if(value === "" || value === null){
                            value = null
                        }
                        else{
                            // 数値が文字列として入ってくるので変換する
                            // NOTE: v-model.numberだと小数点に0が入れられないのでこの対応をしている
                            value = parseFloat(value)
                        }
                        return value
                    }
                },
                {
                    pattern:/^[+-]*[0-9]{1,3}\.{0,1}[0-9]{0,6}$/,
                    message: '数値(整数部3桁まで、小数点第6位まで)を入力してください',
                    trigger: 'blur',
                },
                {
                    validator:(rules,value,callback)=>{
                        if(value === "" || value === null){
                            if((facility.latitude !== "" && facility.latitude !== null)
                                || (facility.range !== "" && facility.range !== null)){
                                    callback(new Error('緯度・検索範囲を入力する場合、経度も入力必須です'))
                                }
                        }
                        callback()
                    },
                    trigger: 'blur'
                }
            ],
            latitude: [
                {
                    type:'number',
                    min:-90,
                    max:90,
                    message: '-90~90の間で入力してください',
                    trigger: 'blur',
                    transform(value){
                        // 数値を入れた後に消すと空白になって型チェックでエラーになるのでnullに変換する
                        if(value === "" || value === null){
                            value = null
                        }
                        else{
                            // 数値が文字列として入ってくるので変換する
                            // NOTE: v-model.numberだと小数点に0が入れられないのでこの対応をしている
                            value = parseFloat(value)
                        }
                        return value
                    }
                },
                {
                    pattern:/^[+-]*[0-9]{1,2}\.{0,1}[0-9]{0,6}$/,
                    message: '数値(整数部2桁まで、小数点第6位まで)を入力してください',
                    trigger: 'blur',
                },
                {
                    validator:(rules,value,callback)=>{
                        if(value === "" || value === null){
                            if((facility.longitude !== "" && facility.longitude !== null)
                                || (facility.range !== "" && facility.range !== null)){
                                    callback(new Error('経度・検索範囲を入力する場合、緯度も入力必須です'))
                                }
                        }
                        callback()
                    },
                    trigger: 'blur'
                }
            ],
            range: [
                {
                    type:'integer',
                    message: '整数を入力してください',
                    trigger: 'blur',
                    transform(value){
                        // 数値を入れた後に消すと空白になって型チェックでエラーになるのでnullに変換する
                        if(value === ""){
                            value = null
                        }
                        return value
                    }
                },
                {
                    validator:(rules,value,callback)=>{
                        if(value === "" || value === null){
                            if((facility.longitude !== "" && facility.longitude !== null)
                                || (facility.latitude !== "" && facility.latitude !== null)){
                                    callback(new Error('経度・緯度を入力する場合、検索範囲も入力必須です'))
                                }
                        }
                        callback()
                    },
                    trigger: 'blur'
                }
            ],
            // picture: [
            //     {
            //         type: 'url',
            //         message: 'URLを入力してください',
            //         trigger: 'blur'
            //     }
            // ]
        }

        // 検索
        const handleSearch = async () => {
            try {
                const res = await http.get('/v1/facilities', { params: { 
                                facilityID: searchCondition.facilityID,
                                facilityName: searchCondition.facilityName,
                                facilityCode: searchCondition.facilityCode,
                                brandID: searchCondition.brandID,
                                zip: searchCondition.zip,
                                country: searchCondition.country,
                                state: searchCondition.state,
                                city: searchCondition.city,
                                isDeleted: searchCondition.isDeleted ? 1 : 0
                            }})
                facilities.splice(0) // リストをクリア
                res.data.facilities.map((f: any) =>  {
                facilities.push(new Facility(f.facilityID, f.facilityName, f.facilityCode, f.brandID, 
                    f.zip, f.country, f.state, f.city, f.address, f.phone, f.longitude, f.latitude, f.range, f.picture, f.isDeleted, f.brandName))
                })

                if (facilities.length > 0) {
                    ElNotification({ type: 'success', message: `${facilities.length} 件ヒットしました` })
                } else {
                    ElNotification({ type: 'error', message: '検索結果がありません' })
                }
            }
            catch (reason: any){
                ElNotification({ type: 'error', message: '処理に失敗しました' })
                throw new Error(`search error: ${reason.response.status} ${reason.response.data.message}`)
            }
        }

        // 新規作成ダイアログ表示
        const showCreateDialog = () => {
            facility.facilityID = ''
            facility.facilityName = ''
            facility.facilityCode = ''
            facility.brandID = ''
            facility.zip = ''
            facility.country = ''
            facility.state = ''
            facility.city = ''
            facility.address = ''
            facility.phone = ''
            facility.longitude = null
            facility.latitude = null
            facility.range = null
            facility.picture = ''
            facility.isDeleted = false

            isEdit.value = false
            isDeleteVisible.value = false
            isDialogVisible.value = true
            fileUrl.value = "";
        }

        // 新規作成
        const handleCreate = () => {
            // 入力チェック
            form.value.validate(async (valid) => { 
                if (!valid) {
                    // validation error
                    return
                }
                try {
                    const data = { 
                        facilityID: facility.facilityID,
                        facilityName: facility.facilityName,
                        facilityCode: facility.facilityCode,
                        brandID: facility.brandID,
                        zip: facility.zip,
                        country: facility.country,
                        state: facility.state,
                        city: facility.city,
                        address: facility.address,
                        phone: facility.phone,
                        longitude: facility.longitude !== "" ? facility.longitude : null,
                        latitude: facility.latitude !== "" ? facility.latitude : null,
                        range: facility.range !== "" ? facility.range : null,
                        picture: facility.picture,
                        isDeleted: facility.isDeleted ? 1 : 0,
                    }
                    const formData = new FormData();
                    formData.append("formData", JSON.stringify(data));
                    if (uploadFile.value) {
                        formData.append('pictureImage', uploadFile.value);
                    }
                    const res = await http.post('/v1/facilities', formData, {
                            headers: {
                                'Content-type': 'multipart/form-data',
                            }
                        })

                    facilities.push(new Facility(res.data.facility.facilityID, facility.facilityName, facility.facilityCode, facility.brandID,
                        facility.zip, facility.country, facility.state, facility.city, facility.address, facility.phone,
                        facility.longitude, facility.latitude, facility.range, res.data.facility.picture, facility.isDeleted ? 1: 0,
                        brands[brands.findIndex((b)=>b.brandID === facility.brandID)].brandName))
                    ElNotification({ type: 'success', message: '正常終了しました' })
                    isDialogVisible.value = false
                }
                catch(reason: any) {
                    ElNotification({ type: 'error', message: '処理に失敗しました' })
                    isDialogVisible.value = false
                    throw new Error(`create facility error: ${reason.response.status} ${reason.response.data.message}`)
                }
                
            })
        }

        // 更新ダイアログ表示
        const showUpdateDialog = (idx: number, row: Facility) => {

            facility.facilityID = row.facilityID
            facility.facilityName = row.facilityName
            facility.facilityCode = row.facilityCode
            facility.brandID = row.brandID
            facility.zip = row.zip
            facility.country = row.country
            facility.state = row.state
            facility.city = row.city
            facility.address = row.address
            facility.phone = row.phone
            facility.longitude = row.longitude
            facility.latitude = row.latitude
            facility.range = row.range
            facility.picture = row.picture
            facility.isDeleted = row.isDeleted

            isEdit.value = true
            isDeleteVisible.value = row.isDeleted
            isDialogVisible.value = true
            fileUrl.value = String(row.picture);
        }

        // 更新
        const handleUpdate = () => {
            // 入力チェック
            form.value.validate(async (valid) => {
                if (!valid) {
                    // validation error
                    return
                }

                try {
                    const data = { 
                        facilityID: facility.facilityID,
                        facilityName: facility.facilityName,
                        facilityCode: facility.facilityCode,
                        brandID: facility.brandID,
                        zip: facility.zip,
                        country: facility.country,
                        state: facility.state,
                        city: facility.city,
                        address: facility.address,
                        phone: facility.phone,
                        longitude: facility.longitude !== "" ? facility.longitude : null,
                        latitude: facility.latitude !== "" ? facility.latitude : null,
                        range: facility.range !== "" ? facility.range : null,
                        picture: facility.picture,
                        isDeleted: facility.isDeleted ? 1 : 0
                    }
                    const formData = new FormData();
                    formData.append("formData", JSON.stringify(data));
                    if (uploadFile.value) {
                        formData.append('pictureImage', uploadFile.value);
                    }
                    const res = await http.patch(`/v1/facilities/${facility.facilityID}`, formData, {
                            headers: {
                                'Content-type': 'multipart/form-data',
                            }
                        })
                    const f = facilities[facilities.findIndex(i => i.facilityID === facility.facilityID)]
                    f.facilityName = facility.facilityName
                    f.facilityCode = facility.facilityCode
                    f.brandID = facility.brandID
                    f.zip = facility.zip
                    f.country = facility.country
                    f.state = facility.state
                    f.city = facility.city
                    f.address = facility.address
                    f.phone = facility.phone
                    f.longitude = facility.longitude
                    f.latitude = facility.latitude
                    f.range = facility.range
                    f.picture = res.data.facility.picture
                    f.isDeleted = facility.isDeleted

                    ElNotification({ type: 'success', message: '正常終了しました' })
                    isDialogVisible.value = false
                }
                catch(reason: any){
                    ElNotification({ type: 'error', message: '処理に失敗しました' })
                    isDialogVisible.value = false
                    throw new Error(`update facility error: ${reason.response.status} ${reason.response.data.message}`)
                }
                
                
            })
        }

        // 削除確認ダイアログ表示
        const showDeleteDialog = (idx: number, row: Facility) => {
            facility.facilityID = row.facilityID
            facility.facilityName = row.facilityName
            facility.facilityCode = row.facilityCode

            isConfirmVisible.value = true
        }

        // 削除
        const handleDelete = async () => {
            try {
                const res = await http.delete(`/v1/facilities/${facility.facilityID}`)
                facilities.splice(facilities.findIndex(i => i.facilityID === facility.facilityID), 1)
                ElNotification({ type: 'success', message: '正常終了しました' })
                isConfirmVisible.value = false
            }
            catch (reason: any){

                ElNotification({ type: 'error', message: '処理に失敗しました' })
                isConfirmVisible.value = false
                throw new Error(`delete facility error: ${reason.response.status} ${reason.response.data.message}`)
            }
        }

        const getBrands = async () => {
            try {
                const res = await http.get('/v1/brands')
                res.data.brands.map((b: any) =>  {
                    brands.push(new Brand(b.brandID, b.brandName, b.brandCode, b.companyID, b.companyName, b.timeZone, b.isDeleted))
                })
            }
            catch (reason){
                ElNotification({ type: 'error', message: 'ブランドマスタ取得処理に失敗しました' })
            }
            
        }

        onMounted(()=>{
            // セレクトボックス用マスタ取得
            getBrands()
            // 初期表示のため検索
            handleSearch()
        })

        // 緯度経度の数値を最大桁まで0埋めする
        const fixLongLat = (target: string) => {
            let value = facility[target]
            if(value !== "" && value !== null){
                const valArr = value.split('.')
                // 小数点7桁以上あるとtoFixed関数が6桁で四捨五入してユーザー入力を変えてしまうので、何もせずにエラーチェックに任せる
                if((valArr.length < 2 || valArr[1].length <= 6)){
                    facility[target] = Number.parseFloat(value).toFixed(6)
                }
            }
        }

        // 画像アップロード
        const uploadFile = ref();
        const fileUrl = ref("");
        const fileSelected = (event) => {
            uploadFile.value = event.target.files[0];
            fileUrl.value = URL.createObjectURL(event.target.files[0]);
        }

        return { searchCondition, facilities, facility, brands,
            form, rules, clearValidate, filter,
            showCreateDialog, showUpdateDialog, showDeleteDialog,
            handleCreate, handleUpdate, handleSearch, handleDelete,
            isEdit, isDialogVisible, isConfirmVisible, isSearchVisible, isDeleteVisible,
            dispColumns, uploadFile, fileSelected, fileUrl, fixLongLat}
    },
})
